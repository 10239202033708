@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap&subset=latin-ext');

body {
  /* background: #364347 url("../images//bg.jpg") no-repeat center center; */
  position: relative;
  font-family: 'Lato', sans-serif;
  font-weight: 400 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.p0 {
  padding: 0;
}

.mt12 {
  margin-top: 12px;
}

.mb25 {
  margin-bottom: 25px !important;
}

.main-content {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.logo-section {
  margin: 18px 0 45px 0;
}

.content-section {
  margin-bottom: 25px;
  text-align: center;
}

.content-section h2 {
  font-size: 38px;
  color: #364347;
  max-width: 450px;
  margin: 0 auto;
  padding: 0;
  margin-bottom: 15px;
  font-weight: 400;
}

.content-section p {
  font-size: 18px;
  color: #364347;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.form-section {
  display: flex;
  justify-content: center;
}

.form-section label {
  font-size: 10px;
  font-weight: normal;
  color: #5e696d;
  text-transform: uppercase;
  display: flex;
  text-align: left;
  margin-bottom: 2px;
  font-family: 'Source Sans Pro', sans-serif;
}

.form-section .form-group {
  margin-bottom: 15px;
}

.form-section .form-control {
  width: 335px;
  height: 40px;
  border-radius: 4px;
}

.form-section .form-control.error {
  border: 1px solid #f84545;
}

.form-section .form-control::placeholder {
  color: #b2c6cd;
}

.form-section .btn.btn-primary {
  width: 335px;
  height: 40px;
  border-radius: 4px;
  background-color: #f58410;
  color: #ffffff;
  border-color: #f58410;
  font-size: 16px;
  font-weight: 400;
}

.form-section .btn.btn-primary.disabled {
  opacity: 0.3;
}

.form-section .btn.btn-primary:hover,
.form-section .btn.btn-primary:active,
.form-section .btn.btn-primary:focus {
  background-color: #cf650f;
  border-color: #cf650f;
}

.gap {
  width: 100%;
  height: 1px;
  background-color: #d6e1e5;
  margin: 0 0 10px 0;
}

footer p {
  font-size: 12px;
  color: #5e696d;
  margin: 0;
  padding: 0;
}

.footer-bg {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

p.terms-txt {
  font-weight: 700;
}

.notes {
  font-size: 14px;
  font-weight: normal;
  color: #f84545;
}

.checkbox-area {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  color: #364347;
  max-width: 300px;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label.checkbox-area {
  text-transform: none;
  font-size: 14px;
  color: #364347;
  font-family: 'Lato', sans-serif;
}

/* Hide the browser's default checkbox */

.checkbox-area input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #d6e1e5;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */

.checkbox-area input:checked ~ .checkmark {
  background-color: #ffffff;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.checkbox-area input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkbox-area .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 12px;
  border: solid #02baf2;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* LOADER */
.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}

.loaderbg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}

.loaderbg .loader_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loaderbg.loaderbgUS {
  background: none;
}

.loaderbg .loader_content .loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}

.loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}

.loaderbg .loader_content .loaderimg {
  width: 70px;
  height: 70px;
  display: inline-block;
}

.loaderbg .loader_content .loaderimg img {
  width: 100%;
  opacity: 0.5;
}

* {
  -webkit-overflow-scrolling: touch;
}

/* LOADER */

/* MODAl */
.modal-header {
  background-color: #e1eef3;
}
.modal-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #3d4c51;
}
/* END MODAl */

@media screen and (max-width: 767px) {
  .logo-section {
    margin-bottom: 30px;
  }
  .content-section h2 {
    font-size: 26px;
    max-width: 315px;
  }
  .content-section {
    margin-bottom: 20px;
  }
  .content-section p {
    font-size: 14px;
    max-width: 300px;
    margin: 0 auto;
  }
  .main-content {
    height: auto;
  }
  .footer-bg {
    position: relative;
  }
  .footer-bg.thanks-footer {
    position: fixed;
  }
  .content-section.thanks-content p {
    max-width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .content-section h2 {
    font-size: 30px;
    max-width: 360px;
  }
  .content-section p {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
  }
  .logo-section {
    margin: 18px 0 35px 0;
  }
  .content-section.thanks-content p {
    max-width: 370px;
  }
}

@media screen and (min-width: 1024px) {
  .gap {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto;
  }
  .content-section h2 {
    font-size: 38px;
    max-width: 470px;
  }
  .content-section p {
    font-size: 18px;
    max-width: 560px;
    margin: 0 auto;
  }
  .logo-section {
    margin: 18px 0 45px 0;
  }
  .content-section.thanks-content p {
    max-width: 400px;
  }
}
